import * as React from 'react';
import router from 'next/router';

import styled from 'styled-components';

import { Button, Grid, SnackBar, Typography } from '@stafes/gochikuru-now-ui';

import { routes } from '../../../config/routes';
import { LoginFormContainer } from '../../../containers/LoginFormContainer';
import { CustomCard } from '../../common/CustomCard';
import { SignInWithSlackButton } from '../../common/SignInWithSlackButton';

interface Props {
  errorMessages?: string[];
}

const LoginBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakPoints.middle}) {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.middle}) {
    min-height: 560px;
    height: calc(
      100vh -
        ${(props) =>
          `${props.theme.variables.headerHeight} - ${
            props.theme.variables.headerHeight
          } - ${props.theme.variables.footerHeightPc} - ${props.theme.spacing(
            8,
          )}px`}
    );
  }
`;

const CardContent = styled(Grid)`
  padding: ${(props) => props.theme.spacing(4)}px;
`;

const SnackBarContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const PartitionText = styled.div<{ borderWidth: number }>`
  margin: ${(props) => props.theme.spacing(4)}px;
  color: ${(props) => props.theme.colors.whiteGray};
  width: 300px;
  text-align: center;
  position: relative;
  font-size: ${(props) => props.theme.font.fontSize.body};
  &:before,
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: ${(props) => props.borderWidth}px;
    background-color: ${(props) => props.theme.colors.whiteGray};
    position: absolute;
    top: 50%;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
`;

const ButtonGrid = styled(Grid)`
  margin-top: ${(props) => props.theme.spacing(4)}px;
`;

const LoginPanel: React.FC<Props> = ({ errorMessages = [] }) => {
  const handleOnClick = () => {
    router.push(routes.registerCompanyBase);
  };

  return (
    <LoginBox>
      <CustomCard
        useBoxShadow={true}
        width="469px"
        css={{ overflow: 'hidden' }}
      >
        <CardContent justifyContent="center">
          <Typography variant="h2">ログイン</Typography>
        </CardContent>
        {errorMessages.length > 0 &&
          errorMessages.map((error: string) => (
            <SnackBarContainer key={error}>
              <SnackBar>{error}</SnackBar>
            </SnackBarContainer>
          ))}
        <CardContent
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <LoginFormContainer />
          <PartitionText borderWidth={110}>もしくは</PartitionText>
          <SignInWithSlackButton />
        </CardContent>
      </CustomCard>
      <Grid justifyContent="center">
        <PartitionText borderWidth={72}>はじめての方はこちら</PartitionText>
      </Grid>
      <ButtonGrid justifyContent="center">
        <Button
          text="ごちクルNow会員登録"
          onClick={handleOnClick}
          variant="normal"
        />
      </ButtonGrid>
    </LoginBox>
  );
};

export { LoginPanel };
